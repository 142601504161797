import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { SoftwareComment } from '../models/software-edit.model';

@Injectable({
  providedIn: 'root',
})
export class SoftwareEditService {
  members: IApi<any>;
  softwareEdit: IApi<any>;
  softwareComments:IApi<SoftwareComment>
  constructor(private http: HttpClient) {
    this.softwareEdit = new GenericApi<any>('softwares', http);
    this.members = new GenericApi<any>('admins', http);
    this.softwareComments = new GenericApi<SoftwareComment>('softwareComments', http)
  }
}
