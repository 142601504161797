import { Directive, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, NgModel, NgControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { AppService } from 'src/app/services/app.service';
import { SearchAddressComponent } from '../../components/search-address/search-address.component';
// import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
declare var google: any;

@Directive({
  selector: '[LocationPicker]',
  exportAs: 'LocationPicker'
})
export class LocationDirective implements OnChanges, OnInit, AfterViewInit {

  @Input() coodinates?: number[] = [];
  @Input() coodinatesPolygon?: number[] = [];
  @Input() formControl: FormControl = new FormControl('');
  @Input() map: boolean = false;
  @Input() mapIconPosition: any;
  @Output() address: EventEmitter<any> = new EventEmitter<any>();
  @Output() fullAddress: EventEmitter<any> = new EventEmitter<any>();
  @Output() coodinatesChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() polygonCoodinatesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  formGroup: FormGroup;
  geocoder: any;
  dialogRef: MatDialogRef<SearchAddressComponent>
  constructor(
    private el: ElementRef<HTMLInputElement>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private Model?: NgModel,
    private control?: NgControl,
    private dialog?: MatDialog
  ) {

  }

  ngOnChanges() {

  }

  ngAfterViewInit() {
    if (this.Model && this.Model.control) {
      this.Model.control.setValue(this.Model.control.value);
    }
    if (this.control && this.control.control) {
      this.formControl = this.control.control as any;
      this.control.control.setValue(this.control.control.value);
    }
    if (this.map) {
      let img = document.createElement('img');
      img.src = 'assets/svg/ic_location.svg';
      img.style.position = "absolute";
      img.style.right = '28px';
      // img.style.right = '18px';
      img.style.top = '34px';
      // img.style.top = '10px';
      img.style.cursor = "pointer";

      if (this.mapIconPosition) {

        for (let key in this.mapIconPosition) {
          img.style[key] = this.mapIconPosition[key];
        }

      }

      img.addEventListener('click', () => {
        this.dialogRef = this.dialog.open(SearchAddressComponent, {
          data: {
            location: this.formControl.value,
            coodinates: this.coodinates,
            place: null,
          }
        });
        this.dialogRef.afterClosed().subscribe(r => {
          if (r && r.location) {
            if (this.formControl) this.formControl.setValue(r.location);
            if (this.Model.control) this.Model.control.setValue(r.location);
            if (this.control.control) this.control.control.setValue(r.location);
          }
          if (r && r.coodinates) {
            this.coodinates = r.coodinates;
            this.coodinatesChange.emit({c:r.coodinates,t:r.type || 'pinDrop'} as any);
            // this.coodinatesChange.emit(r.coodinates);
          }
          if(r && r.coodinatesPolygon){
            this.coodinatesPolygon = r.coodinatesPolygon
            this.polygonCoodinatesChange.emit({c: r.coodinatesPolygon, t: r.type|| 'polygon'} as any)
            // this.polygonCoodinatesChange.emit(r.coodinatesPolygon )
          }
          if (r && r.place) {
            this.address.emit(this.getFullAddress(r.place));
          }
        })
      })
      this.el.nativeElement.after(img);
    }




    this.el.nativeElement.addEventListener('click', () => {
      this.dialogRef = this.dialog.open(SearchAddressComponent, {
        data: {
          location: this.formControl.value,
          coodinates: this.coodinates,
          place: null,
        }
      });
      this.dialogRef.afterClosed().subscribe(r => {
        if (r && r.location) {
          if (this.formControl) this.formControl.setValue(r.location);
          if (this.Model.control) this.Model.control.setValue(r.location);
          if (this.control.control) this.control.control.setValue(r.location);
        }
        if (r && r.coodinates) {
          this.coodinates = r.coodinates;
          this.coodinatesChange.emit({c:r.coodinates,t:r.type || 'pinDrop'} as any);
          // this.coodinatesChange.emit(r.coodinates);
        }
        if(r && r.coodinatesPolygon){
          this.coodinatesPolygon = r.coodinatesPolygon
          this.polygonCoodinatesChange.emit({c: r.coodinatesPolygon, t: r.type|| 'polygon'} as any)
          // this.polygonCoodinatesChange.emit(r.coodinatesPolygon )
        }
        if (r && r.place) {
          this.address.emit(this.getFullAddress(r.place));
        }
      })
    })
  }

  ngOnInit() {
    this.gooleSetUp();
  }

  gooleSetUp() {
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement);
      // autocomplete.setFields(["address_component"]);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(async () => {
          let place: any = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          const fullAddress = this.getFullAddress(place);
          if (this.formControl) this.formControl.setValue(place.formatted_address);
          this.fullAddress.emit(place.formatted_address);
          this.address.emit(fullAddress);
          this.coodinates = [place.geometry.location.lng(), place.geometry.location.lat()]
          this.coodinatesChange.emit([place.geometry.location.lng(), place.geometry.location.lat()]);
        });
      });
    });
  }


  getFullAddress(place) {
    let location = {
      street: this.findAddress(place.address_components, "sublocality"),
      city: this.findAddress(place.address_components, 'locality'),
      country: this.findAddress(place.address_components, 'country'),
      zipcode: this.findAddress(place.address_components, 'postal_code'),
      state: this.findAddress(place.address_components, 'administrative_area_level_1'),
    }
    return location;
  }

  findAddress(address: any[], key: string) {
    if (!address.length) return "";
    let ad;
    if (key === "sublocality") {
      ad = address.filter(i => (i.types.includes('street_number') || i.types.includes('route') || i.types.includes("neighborhood") || i.types.includes('administrative_area_level_3') || i.types.includes("sublocality"))).map(i => i.long_name).join(", ");
      return ad;
    } else {
      ad = address.find(i => i.types.includes(key));
      if (ad && ad.long_name) return ad.long_name;

    }
  }

  // this.el.nativeElement.add

}
