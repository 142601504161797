import { NotificationModel } from './../models/notification.model';
// import { NotificationService } from './../services/notification.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { AppService } from 'src/app/services/app.service';
import { AddsidelistComponent } from '../modules/share-module/components/addsidelist/addsidelist.component';
import { FirebaseMessageService } from '../services/notification/firebase-message.service';
import { PresenceService } from '../services/support-service/presense.service';
import { TeamService } from '../services/team.service';
import { Page } from '../http-handler/common/contracts/page';
import { AppService } from '../services/app.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  tabs = [];
  projectManagerTabs = [];
  saleManagerTabs = [];

  loading: boolean = false;

  notifications: Page<NotificationModel> = new Page({
    api: this.notificationService.notification,
    properties: new NotificationModel(),
    serverPaging: false,
  });

  constructor(
    public appService: AppService,
    private router: Router,
    private toasterService: AppService,
    private _matDialog: MatDialog,
    private _firebaseMessageService: FirebaseMessageService,
    private presenceService: PresenceService,
    private teamService: TeamService,
    private notificationService: NotificationService
  ) {
    this.tabs = [
      {
        title: 'Dashboard',
        link: '/pages/dashboard',
        icon: 'dashboard.svg',
      },
      // {
      //   title: 'Real Estate',
      //   link: '/pages/real-estate',
      //   icon: 'Real Estate.svg',
      // },
      {
        title: 'Accounts',
        link: '/pages/accounts',
        icon: 'Account.svg',
      },
      {
        title: 'Contacts',
        link: '/pages/contacts',
        icon: 'Contact.svg',
      },
      {
        title: 'Leads',
        link: '/pages/leads',
        icon: 'Lead.svg',
      },
      {
        title: 'Deals',
        link: '/pages/deals',
        icon: 'dollar-sign.svg',
      },
      {
        title: 'Listing',
        link: '/pages/listing',
        icon: 'Media.svg'
      },
      {
        title: 'Shoots',
        link: '/pages/shoots',
        icon: 'Shoot.svg',
      },
      {
        title: 'Media',
        link: '/pages/media',
        icon: 'Media.svg',
      },




      // {
      //   title: 'Task',
      //   link: '/pages/tasks',
      //   icon: 'Task.svg',
      // },
      // {
      //   title: 'Team',
      //   link: '/pages/admins',
      //   icon: 'Team.svg',
      // },
      // {
      //   title: 'Client Request',
      //   link: '/pages/clientRequest',
      //   icon: 'Team.svg',
      // },
    ];

    this.projectManagerTabs = [
      {
        title: 'Dashboard',
        link: '/pages/dashboard',
        icon: 'dashboard.svg',
      },
      {
        title: 'Account',
        link: '/pages/accounts',
        icon: 'Account.svg',
      },
      {
        title: 'Contact',
        link: '/pages/contacts',
        icon: 'Contact.svg',
      },
      {
        title: 'Deal',
        link: '/pages/deals',
        icon: 'dollar-sign.svg',
      },
      {
        title: 'Shoots',
        link: '/pages/shoots',
        icon: 'Shoot.svg',
      },
      {
        title: 'Media',
        link: '/pages/media',
        icon: 'Media.svg',
      },
      {
        title: 'Task',
        link: '/pages/tasks',
        icon: 'Task.svg',
      },
    ];

    this.saleManagerTabs = [
      {
        title: 'Dashboard',
        link: '/pages/dashboard',
        icon: 'dashboard.svg',
      },
      {
        title: 'Real Estate',
        link: '/pages/real-estate',
        icon: 'Real Estate.svg',
      },
      {
        title: 'Lead',
        link: '/pages/leads',
        icon: 'Lead.svg',
      },
      {
        title: 'Deal',
        link: '/pages/deals',
        icon: 'dollar-sign.svg',
      },
      {
        title: 'Task',
        link: '/pages/tasks',
        icon: 'Task.svg',
      },
    ];
  }

  not?: any;

  async fetchNotifications() {
    this.loading = true;
    await this.notifications.fetch().then((res) => {
      this.not = res?.items;
      this.notificationService.setbadgeCount(res?.totalRecords);
      this.loading = false;
    });
  }

  @HostListener('window:focus')
  onFocus() {
    this.fetchNotifications();
  }

  async onBellClick() {
    this.fetchNotifications();
    await this.notificationService.updateAll().then(() => {
      this.notificationService.resetBadgeCount();
    });
  }

  ngOnInit() {
    let item = this.tabs.filter((i) => this.router.url.includes(i.link));
    if (item.length) {
      this.navigate(
        item[0].title == 'Dashboard' ? 'Earth Virtual' : item[0].title
      );
    } else {
      this.navigate('Earth Virtual');
    }
    this._firebaseMessageService.receiveMessage();

    this.fetchNotifications();

    // let user = this.appService?.user
    //     if(user?.roleType == 'saleManager' && user?.status == 'active'){
    //       localStorage.clear();
    //       localStorage.removeItem('roleType');
    //       localStorage.removeItem('token');
    //       this.router.navigate(['/auth/login']);
    //     }
  }

  navigate(heading) {
    this.appService.pageHeading = heading;
  }

  app() {
    this._matDialog.open(AddsidelistComponent, {
      width: '300px',
      maxWidth: '90%',
    });
  }

  activeNavigator(heading) {
    this.appService.pageHeading = heading;
    return 'active';
  }

  toggle() {
    this.appService.sideMenuToggle = !this.appService.sideMenuToggle;
  }

  logOut() {
    window.localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  async deleteOneNotitication(event: Event, id: any) {
    this.loading = true;
    event.stopPropagation();
    try {
      await this.notificationService.notification.remove(id);
      this.fetchNotifications();
    } catch (error) {
      this.loading = false;
    }
  }

  async deleteAllNotitications(event: Event) {
    this.loading = true;
    event.stopPropagation();
    try {
      await this.notificationService.deleteAll();
      this.fetchNotifications();
    } catch (error) {
      this.loading = false;
    }
  }

  goToDetail(item: any) {
    if (item) {
      if (item?.entityName == 'newContact') {
        this.router.navigate([`/pages/contacts/detail/${item?.entityId}`]);
      }
      if (item?.entityName == 'newLead') {
        this.router.navigate([`/pages/leads/detail/${item?.entityId}`]);
      }
      if (item?.entityName == 'teamMember') {
        this.router.navigate([
          `/pages/admins/resource/applicants/${item?.entityId}`,
        ]);
      }
    }
  }
}
