import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Admin } from '../models/lead.model';
import { AppService } from './app.service';
import { TeamMember } from '../models/team-member.model';
// import { Team } from '../models/team.model';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  team: IApi<Admin>;
  teamMember: IApi<TeamMember>;
  countryCode: IApi<TeamMember>;

  constructor(http: HttpClient, private _appService: AppService) {
    this.team = new GenericApi<Admin>('admins', http);
    this.teamMember = new GenericApi<Admin>('teamMembers', http);
    this.countryCode = new GenericApi<Admin>('countryCodes', http);
  }

  approveReject(profileStatus, admin: Admin) {
    return this.team
      .update(admin?.id as any, { profileStatus }, null as any, `approve/reject/${admin.id}`)
      .then((d) => {
        admin.profileStatus = profileStatus;
        this._appService.toastr.success(
          profileStatus === 'approved'
            ? `Request approved successfully.`
            : 'Request rejected.'
        );
        return d;
      })
      .catch((err) => {
        this._appService.toastr.error(err);
        return Promise.reject(err);
      });
  }

  changeStatus(status, admin: Admin) {
    return this.team
      .update(admin?.id as any, { status })
      .then((d) => {
        admin.status = status;
        this._appService.toastr.success(
          `${admin.displayName} successfully ${status}.`
        );
        return d;
      })
      .catch((err) => {
        this._appService.toastr.error(err);
        return Promise.reject(err);
      });
  }

  deleteResource(admin: Admin) {
    return this.team
      .remove(admin?.id as any)
      .then((d) => {
        this._appService.toastr.success(
          `${admin.displayName} successfully deleted.`
        );
        return d;
      })
      .catch((err) => {
        this._appService.toastr.error(err);
        return Promise.reject(err);
      });
  }
}
