import { BillingAddress } from './billing-address.model';
import { MailingAddress } from './mailing-address.model';
import { RealEstate } from './real-estate.model';
import { OtherAddress } from './other-address.model';
import { Lead } from './lead.model';
import { Deal } from './deal.model';
import { VisualDataAccount } from './visual.data.model';

export class Account {
    id?: number;
    name?: string;
    countryCode?: string;
    accountAttachments?:VisualDataAccount[] = [];
    addressHQ?:string;
    addressCoordinatesHQ?:number[] = [];
    relationshipLevel?: string;
    country: string;
    phone?: string;
    fax?: string;
    website?: string;
    suiteNumber?: string;
    subIndustryIds?:number[] = [];
    address?:string;
    landmark?:string;
    city?: string;
    state?: string;
    type?: string;
    noteForDataSource?:string;
    street?:string;
    companyPhone?: string;
    companyFax?: string;
    businessType?: string;
    dataSource?: string;
    aboutUs?: string;
    mission?: string;
    vision?: string;
    values?: string;
    foundingDate?: string;
    noOfEmployees?: string;
    annualRevenue?: string;
    founder?: string;
    story?: string;
    email?: string;
    new?: string;
    facebookId?: string;
    linkedId?: string;
    twitterId?: string;
    instagramId?: string;
    logo?: string;
    aboutUsVideo?: string;
    storyVideo?: string;
    visionVideo?: string;
    recurementVideo?: string;
    location?: string;
    locationCoordinates?: string;
    industryId?: number;
    accountAddresses?: OtherAddress[] = [];
    billingAddress?:OtherAddress;
    maillingAddress?:OtherAddress;
    status: string;
    industryServiceIds?:number[] = [];
    realEstateId: number;
    industry?: Industry = new Industry();
    otherIndustryName?:string;
    realEstate?: RealEstate = new RealEstate();
    subIndustries?: SubIndustry[] = [];
    industryServices?: IndustryServices[] = [];
    leads?: Lead[] = [];
    deals?: Deal[] = [];
    editAdditional?: boolean = false;
    editMarketing?: boolean = false;
    editAccount?: boolean = false;
    btnDisable?: boolean;
    selected?: boolean = false;
    add?: boolean;
    landmarkHQ?:string;
    suiteNumberHQ?:string;
    unitHQ?:string;
}

export class SmallAccount {
    id?: number;
    name?: string;
    relationshipLevel?: string;
    phone?: string;
    fax?: string;
    website?: string;
    type?: string;
    annualRevenue?: string;
    description?: string;
    noOfEmployees?: number;
    rating?: string;
    industryId?: number;
    btnDisable?: boolean;
    industry?: Industry = new Industry();
}

export class Industry {
    id?: number;
    name?: string;
    subIndustries?: SubIndustry[] = [];
    industryServices?: IndustryServices[] = [];
}

export class SubIndustry {
    id?: number;
    name?: string;
    industryId?: number;
    selected?: boolean = false;
}

export class IndustryServices {
    id?: number;
    name?: string;
    industryId?: number
    selected?: boolean = false;
    industryServiceId?:number;
}

