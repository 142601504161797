import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddEmailComponent } from 'src/app/modules/email-list/add-email/add-email.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'add-options',
  templateUrl: './addsidelist.component.html',
  styleUrls: ['./addsidelist.component.css'],
})
export class AddsidelistComponent implements OnInit {
  tabs = [
    {
      title: 'Real Estate',
      icon: '../../../assets/images/Real Estate.png',
      name: 'New Real Estate',
      link: '/pages/real-estate/create',
    },
    {
      title: 'Account',
      icon: '../../../assets/images/Account.png',
      name: 'New Account',
      link: '/pages/accounts/create',
    },
    {
      title: 'Contact',
      icon: '../../../assets/images/Contact.png',
      name: 'New Contact',
      link: '/pages/contacts/create',
    },
    {
      title: 'Lead',
      icon: '../../../assets/images/Lead.png',
      name: 'New Lead',
      link: '/pages/leads/create',
    },
    {
      title: 'Deal',
      icon: '../../../assets/images/Deal.png',
      name: 'New Deal',
      link: '/pages/deals/create',
    },
    {
      title: 'Listing',
      icon: '../../../assets/images/Deal.png',
      name: 'New Listing',
      link: '/pages/listing/create',
    },
    {
      title: 'Shoots',
      icon: '../../../assets/images/Shoot.png',
      name: 'New Shoot',
      link: '/pages/shoots/create',
    },
    {
      title: 'Media',
      icon: '../../../assets/images/Media.png',
      name: 'New Media',
      link: '/pages/media/create',
    },
    {
      title: 'Task',
      icon: '../../../assets/images/Task.png',
      name: 'New Task',
      link: '/pages/tasks/create',
    },
    {
      title: 'Team',
      icon: '../../../assets/images/Team.png',
      name: 'New Team',
      link: '/pages/admins/create',
    },
    // {
    //   title: 'Client Request',
    //   icon: '../../../assets/images/Team.png',
    //   name: 'New Client Request',
    //   link: '/pages/clientRequest/create',
    // },
    // {
    //   title: 'Email List',
    //   icon: '../../../assets/images/Team.png',
    //   name: 'New Email List',
    //   link: '/pages/clientRequest/create',
    // },
    {
      title: 'New Software Edit',
      icon: '../../../assets/images/software-edit.png',
      name: 'New Software Edit',
      link: '/pages/software-edit/create',
    },
  ];

  projectManagerTabs = [
    {
      title: 'Account',
      icon: '../../../assets/images/Account.png',
      name: 'New Account',
      link: '/pages/accounts/create',
    },
    {
      title: 'Contact',
      icon: '../../../assets/images/Contact.png',
      name: 'New Contact',
      link: '/pages/contacts/create',
    },
    {
      title: 'Deal',
      icon: '../../../assets/images/Deal.png',
      name: 'New Deal',
      link: '/pages/deals/create',
    },
    {
      title: 'Shoots',
      icon: '../../../assets/images/Shoot.png',
      name: 'New Shoot',
      link: '/pages/shoots/create',
    },
    {
      title: 'Media',
      icon: '../../../assets/images/Media.png',
      name: 'New Media',
      link: '/pages/media/create',
    },
    {
      title: 'Task',
      icon: '../../../assets/images/Task.png',
      name: 'New Task',
      link: '/pages/tasks/create',
    },
  ];

  saleManagerTabs = [
    {
      title: 'Real Estate',
      icon: '../../../assets/images/Real Estate.png',
      name: 'New Real Estate',
      link: '/pages/real-estate/create',
    },
    {
      title: 'Lead',
      icon: '../../../assets/images/Lead.png',
      name: 'New Lead',
      link: '/pages/leads/create',
    },
    {
      title: 'Deal',
      icon: '../../../assets/images/Deal.png',
      name: 'New Deal',
      link: '/pages/deals/create',
    },
    {
      title: 'Task',
      icon: '../../../assets/images/Task.png',
      name: 'New Task',
      link: '/pages/tasks/create',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<AddsidelistComponent>,
    private router: Router,
    public appService: AppService,
    private dialog: MatDialog
  ) {
    dialogRef.addPanelClass('add-options');
  }

  ngOnInit() { }
  navigate(item) {
    if (item?.link) {
      this.router.navigate([item.link]);
    } else {
      this.router.navigate(['/pages/email-list']);
      this.dialog.open(AddEmailComponent, {
        width: '600px',
        height: 'auto',
        panelClass: 'dialogClass',
        disableClose: true,
      });
    }

    this.Heading(item.title);
    this.dialogRef.close();
  }

  Heading(heading) {
    this.appService.pageHeading = heading;
  }
}
