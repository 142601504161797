<div class="list">
    <nb-card class="mb-0">
        <nb-card-header>
            <div class="header">
                <p class="head m-0">ADD</p>
                <!-- <button class="ml-auto" mat-dialog-close mat-icon-button>
                    <mat-icon>clear</mat-icon>
                </button> -->
                <button class="icon-btn md" (click)="dialogRef.close(false)"   mat-dialog-close>
                  <img width="40px" src="assets/images/cancel-button.svg" alt="" />
                </button>
            </div>
        </nb-card-header>
        <!-- admin card body -->
        <nb-card-body *ngIf="appService?.user?.role?.name === 'admin' || appService?.user?.roleType === 'admin'">
            <div class="item" *ngFor="let item of tabs" routerLinkActive="active" (click)="navigate(item)">
                <img [src]="item.icon">
                <p class="list">{{item.name}}</p>
            </div>
        </nb-card-body>

        <!-- project manager card -->

        <nb-card-body
            *ngIf="appService?.user?.roleType === 'projectManager' || appService?.user?.role?.name === 'projectManager'">
            <div class="item" *ngFor="let item of projectManagerTabs" routerLinkActive="active"
                (click)="navigate(item)">
                <img [src]="item.icon">
                <p class="list">{{item.name}}</p>
            </div>
        </nb-card-body>

        <!-- sale manager card -->

        <nb-card-body
            *ngIf="appService?.user?.roleType === 'saleManager' || appService?.user?.role?.name === 'saleManager'">
            <div class="item" *ngFor="let item of saleManagerTabs" routerLinkActive="active" (click)="navigate(item)">
                <img [src]="item.icon">
                <p class="list">{{item.name}}</p>
            </div>
        </nb-card-body>

    </nb-card>
</div>
