import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { AuthGuard } from './guards/auth.guard';
import { ErrorHandleComponent } from './elements/error-handle/error-handle.component';
import { LoginGuard } from './guards/login.guard';
import { ClientPanelGuard } from './guards/client-panel.guard';
import { AdminPanelGuard } from './guards/admin-panel.guard';
import { ExternalResourcePanel } from './guards/external-resource-panel';

const childRoutes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'real-estate',
    loadChildren: () =>
      import('./modules/Real-Estate/real-estate.module').then(
        (m) => m.RealEstateModule
      ),
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('./modules/accounts/accounts.module').then(
        (m) => m.AccountsModule
      ),
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./modules/contacts/contacts.module').then(
        (m) => m.ContactsModule
      ),
  },
  {
    path: 'leads',
    loadChildren: () =>
      import('./modules/Lead/lead.module').then((m) => m.LeadModule),
  },
  {
    path: 'deals',
    loadChildren: () =>
      import('./modules/deal/deal.module').then((m) => m.DealModule),
  },
  {
    path: 'software-edit',
    loadChildren: () =>
      import('./modules/software-edit/software-edit.module').then(
        (m) => m.SoftwareEditModule
      ),
  },
  {
    path: 'shoots',
    loadChildren: () =>
      import('./modules/shoots/shoots.module').then((m) => m.ShootsModule),
  },
  // { path: 'goals-tasks', loadChildren: () => import('./modules/task/task.module').then((m) => m.TaskModule), },
  {
    path: 'admins',
    loadChildren: () =>
      import('./modules/resources/resources.module').then(
        (m) => m.ResourcesModule
      ),
  },
  {
    path: 'media',
    loadChildren: () =>
      import('./modules/media/media.module').then((m) => m.MediaModule),
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./modules/myAccount/myAccount.module').then(
        (m) => m.MyAccountModule
      ),
  },
  {
    path: 'quote-forms',
    loadChildren: () =>
      import('./modules/quote-forms/quote-forms.module').then(
        (m) => m.QuoteFormsModule
      ),
  },
  {
    path: 'template',
    loadChildren: () =>
      import('./modules/templates/templates.module').then(
        (m) => m.TemplateModule
      ),
  },
  {
    path: 'tasks',
    loadChildren: () =>
      import('./modules/tasks-and-goals/tasks-and-goals.module').then(
        (m) => m.TasksAndGoalsModule
      ),
  },
  {
    path: 'clientRequest',
    loadChildren: () =>
      import('./modules/client-request/client-request.module').then(
        (m) => m.ClientRequestModule
      ),
  },

  {
    path: 'email-list',
    loadChildren: () =>
      import('./modules/email-list/email-list.module').then(
        (m) => m.EmailListModule
      ),
  },
  {
    path: 'listing',
    loadChildren: () =>
      import('./modules/Listing/listing.module').then((m) => m.ListingModule),
  },

  //job-board-module

  {
    path: 'job',
    loadChildren: () =>
      import(
        './modules/admin-console/components/job-board/job-board.module'
      ).then((m) => m.JobBoardModule),
  },
  {
    path: 'training',
    loadChildren: () =>
      import(
        './modules/admin-console/components/training/training.module'
      ).then((m) => m.TrainingModule),
  },

  {
    path: 'chat',
    loadChildren: () =>
      import(
        '../app/modules/share-module/components/chat-bot/chat-bot.module'
      ).then((m) => m.ChatBotModule),
  },

  { path: '**', component: ErrorHandleComponent },
];

const routes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: 'link-expired', component: ErrorHandleComponent },
  {
    path: 'client',
    loadChildren: () =>
      import('./modules/cleint/cleint.module').then((m) => m.CleintModule),
    canActivate: [AuthGuard, ClientPanelGuard],
  },
  {
    path: 'pages',
    component: PagesComponent,
    children: childRoutes,
    canActivate: [AuthGuard, AdminPanelGuard],
  },
  {
    path: 'admin-console',
    loadChildren: () =>
      import('./modules/admin-console/admin-console.module').then(
        (m) => m.AdminConsoleModule
      ),
    canActivate: [AuthGuard, AdminPanelGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard, AdminPanelGuard],
  },
  {
    path: 'business-categories/:id',
    loadChildren: () =>
      import('./modules/business-categories/business-categories.module').then(
        (m) => m.BusinessCategoriesModule
      ),
    canActivate: [AuthGuard, AdminPanelGuard],
  },
  {
    path: 'invitation',
    loadChildren: () =>
      import('./modules/invitation/invitation.module').then(
        (m) => m.InvitationModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'external-resource',
    loadChildren: () =>
      import(
        './modules/external-resource-console/external-admin/external-admin.module'
      ).then((m) => m.ExternalAdminModule),
    canActivate: [AuthGuard, ExternalResourcePanel],
  },
  {
    path: 'external-resource/auth',
    loadChildren: () =>
      import('./modules/external-resource-console/auth/auth.module').then(
        (m) => m.AuthModule
      ),
    canActivate: [LoginGuard],
  },

  { path: '**', redirectTo: 'pages' },
];
// pages canActivate: [AuthGuard]
// real-estate canActivate: [LoginGuard]
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
