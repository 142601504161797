import { Directive, Output, EventEmitter, ElementRef, HostListener, OnInit, OnChanges, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

// @Directive({
//     selector: 'input[hyphen]'
// })
// export class HyphenDirective implements OnInit, OnChanges {

//     previousValue: any = '';
//     @Input() value: string;

//     constructor(
//         private el: ElementRef<HTMLInputElement>,
//         private control: NgControl
//     ) {



//     }

//     ngOnInit() {

//     }

//     ngOnChanges() {
//         if (this.value) {
//             this.hyphen(this.el.nativeElement)
//         }
//     }

//     @HostListener('change', ['$event']) onChange(e) {
//         // //console.log(e.target.value);
//     }

//     @HostListener('input', ['$event']) onInput(e) {
//         const input = e.target;
//         this.hyphen(input)
//     }

//     @HostListener('keypress', ['$event']) onKeyPress(e) {
//         if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 45) {
//             e.target.value = this.previousValue;
//             this.control.control?.setValue(this.previousValue);
//         };
//     }

//     hyphen(input) {
//         if (!parseInt(input.value)) {
//             this.previousValue = null;
//             return input.value = null;
//         };
//         let val = input && input.value ? input.value.match(/\d+/g).join("").replace(/_/, '') : '';
//         let numbers = [];
//         for (let i = 0; i < val.length; i += 3) {
//             numbers.push(val?.substr(i, i == 6 ? 4 : 3));
//             if (i == 6) { break }
//         };
//         input.value = numbers.join('-');
//         this.control.control?.setValue(input.value);
//         this.previousValue = input.value;
//     }

// }




















// @Directive({
//   selector: 'input[hyphen]'
// })
// export class HyphenDirective implements OnInit, OnChanges {

//   previousValue: any = '';
//   @Input() value: string;

//   constructor(
//       private el: ElementRef<HTMLInputElement>,
//       private control: NgControl
//   ) {}

//   ngOnInit() {}

//   ngOnChanges() {
//       if (this.value) {
//           this.hyphen(this.el.nativeElement);
//       }
//   }

//   @HostListener('change', ['$event']) onChange(e) {
//       // //console.log(e.target.value);
//   }

//   @HostListener('input', ['$event']) onInput(e) {
//       const input = e.target;
//       this.hyphen(input);
//   }

//   @HostListener('keypress', ['$event']) onKeyPress(e) {
//       if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 45) {
//           e.target.value = this.previousValue;
//           this.control.control?.setValue(this.previousValue);
//       };
//   }

//   hyphen(input) {
//       if (!parseInt(input.value)) {
//           this.previousValue = null;
//           return input.value = null;
//       };

//       let val = input && input.value ? input.value.match(/\d+/g).join("").replace(/_/, '') : '';
//       let numbers = [];
//       let i = 0;


//       while (i < val.length) {
//           if (i === 3 || i === 6 || i === 9 || i === 12 || i === 15) {
//               numbers.push(val.substring(i, i + 4));
//               i += 4;
//           } else {
//               numbers.push(val.substring(i, i + 3));
//               i += 3;
//           }
//       }

//       input.value = numbers.join('-');
//       this.control.control?.setValue(input.value);
//       this.previousValue = input.value;
//   }
// }




@Directive({
  selector: 'input[hyphen]'
})
export class HyphenDirective implements OnInit, OnChanges {

  previousValue: any = '';
  @Input() value: string;

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private control: NgControl
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.value) {
      this.hyphen(this.el.nativeElement);
    }
  }

  @HostListener('change', ['$event']) onChange(e: any) {}

  @HostListener('input', ['$event']) onInput(e: any) {
    const input = e.target;
    this.hyphen(input);
  }

  // @HostListener('keypress', ['$event']) onKeyPress(e: KeyboardEvent) {
  //   if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 45) {
  //     e.preventDefault();
  //     e.target.value = this.previousValue as any;
  //     this.control.control?.setValue(this.previousValue);
  //   }
  // }


  @HostListener('keypress', ['$event']) onKeyPress(e: KeyboardEvent) {
    const target = e.target as HTMLInputElement | null;

    if (!target) {
      return; // Safeguard against null target
    }

    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 45) {
      e.preventDefault();
      target.value = this.previousValue || ''; // Safely handle null `previousValue`
      this.control.control?.setValue(this.previousValue);
    }
  }

  hyphen(input: HTMLInputElement) {
    if (!parseInt(input.value, 10)) {
      this.previousValue = null;
      return (input.value = '');
    }

    // Extract only numbers from the input
    let val = input.value.replace(/\D/g, '');

    // Format the number into groups: 3-3-4-4
    if (val.length <= 3) {
      val = val.substring(0, 3);
    } else if (val.length <= 6) {
      val = `${val.substring(0, 3)}-${val.substring(3, 6)}`;
    } else if (val.length <= 10) {
      val = `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(6, 10)}`;
    } else if (val.length <= 14) {
      val = `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(6, 10)}-${val.substring(10, 14)}`;
    }

    // Update the input value and control value
    input.value = val;
    this.control.control?.setValue(val);
    this.previousValue = val;
  }
}
