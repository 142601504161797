// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//     name: 'hyphenPipe'
// })
// export class HyphenPipe implements PipeTransform {

//     transform(value: string, ...args: any[]): any {
//         if (!value) return '';
//         if(value.length !== 10) return value;
//         let val = (value && value.match(/\d+/g)) ? value.match(/\d+/g).join("").replace(/_/, '') : '';
//         let numbers = [];
//         for (let i = 0; i < val.length; i += 3) {
//             numbers.push(val.substr(i, i == 6 ? 4 : 3));
//             if (i == 6) { break }
//         }
//         return numbers.join('-');


//     }

// }

















// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'hyphenPipe'
// })
// export class HyphenPipe implements PipeTransform {
//   transform(value: string): string {
//     if (!value) return '';


//     const numericValue = value.match(/\d+/g)?.join('') || '';

//     const defaultGroupSizes = [2, 3, 3, 5];


//     const groups: string[] = [];
//     let currentIndex = 0;


//     for (const size of defaultGroupSizes) {
//       if (currentIndex >= numericValue.length) break;
//       groups.push(numericValue.substring(currentIndex, currentIndex + size));
//       currentIndex += size;
//     }

//     while (currentIndex < numericValue.length) {
//       const remainingChunkSize = Math.min(5, numericValue.length - currentIndex);
//       groups.push(numericValue.substring(currentIndex, currentIndex + remainingChunkSize));
//       currentIndex += remainingChunkSize;
//     }


//     return groups.join('-');
//   }
// }








import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphenPipe',
})
export class HyphenPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    // Extract only numeric digits from the input
    const numericValue = value.match(/\d+/g)?.join('') || '';

    if (numericValue.length === 10) {
      // Format for 10 digits
      return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
    } else if (numericValue.length === 12 || numericValue.length === 14) {
      // Format for 12 or 14 digits
      return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}-${numericValue.slice(10)}`;
    }

    // Default: return unformatted if not 10, 12, or 14 digits
    return numericValue;
  }
}
